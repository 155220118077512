const api = async (url: string, action: string, params: any, type = 'json') => {
    let options = {};
    
    let request = process.env.REACT_APP_API_DOMAIN + url;
    // if (APP_ENV.ENV === 'staging') {
    //     request = 'https://parechil.web-tas.net/api' + url
    // } else if((APP_ENV.ENV === 'production')) {
    //     request = 'https://api.parchil.org/api' + url
    // }
    
    for(const key in params) {
        if (!params[key]) {
            delete params[key]
        }
    }
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((_) => _.startsWith("XSRF-TOKEN")) || "";
    const csrfToken = decodeURIComponent(cookie.split("=")[1]);
    if (action === 'GET') {
        const urlSearchParam = new URLSearchParams(params).toString();
        options = {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "X-XSRF-TOKEN": csrfToken,
                "Content-Type": "application/json;charset=utf-8"
            },
            credentials: 'include'
        }  
        request += '?' + urlSearchParam
    } else if(action==='POST') {
        options = {
            method: "POST",
            body: type==='file'?params : JSON.stringify(params),
            headers: {
                Accept: "application/json",
                'User-Agent': 'parechil-app-api-request',
                "X-XSRF-TOKEN": csrfToken,
                "Content-Type": type==='file'?'multipart/form-data;': "application/json;charset=utf-8"
            },
            credentials: 'include'
        }
    }

    return await fetch(request, options)
        .then(res => res.json())
        .then(json => {
            return json;
        })
        .catch(err => {
            throw err
        });
}
export default api