// in posts.js
import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, useRecordContext, ImageInput, ImageField, RadioButtonGroupInput } from 'react-admin';
import BookIcon from '@mui/icons-material/Book';
export const PostIcon = BookIcon;

export const UserList = () => (
    <List title="ユーザー一覧">
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField label="本名" source="name" />
            <TextField source="nickname" label="ニックネーム" />
            <TextField source="email" label="メールアドレス" />
            <TextField source="status" label="ステータス" />
            <TextField source="detail.personal_authentication_status" label="本人認証" />
            <EditButton />
        </Datagrid>
    </List>
);

const UserTitle = () => {
    const record = useRecordContext();
    return <span>ユーザー {record ? `"${record.nickname}"` : ''}</span>;
};

export const UserEdit = () => {
    return (
    <Edit title={<UserTitle />}>
        <SimpleForm>
            <TextInput fullWidth disabled label="ID" source="id" />
            <TextInput fullWidth disabled label="名前" source="name" />
            <TextInput fullWidth disabled label="ニックネーム" source="nickname" />
            <ImageField fullWidth source="icon_image" />
            <DateInput fullWidth disabled label="誕生日" source="birthday" />
            <TextInput fullWidth disabled label="年齢" source="age" />
            <TextInput fullWidth disabled label="性別" source="gender" />
            <TextInput fullWidth disabled label="メールアドレス" source="email" />
            <TextInput fullWidth disabled label="電話番号" source="tel" />
            <DateInput fullWidth disabled label="登録日" source="created_at" />
            <TextInput fullWidth disabled label="住所" source="address" />
            <TextInput fullWidth disabled label="都道府県" source="prefecture" />
            <TextInput fullWidth disabled label="市区町村" source="municipaliti" />
            <TextInput fullWidth disabled label="出身都道府県" source="from_prefecture" />
            <TextInput fullWidth disabled label="出身市区町村" source="from_municipaliti" />
            <TextInput fullWidth disabled label="ひとり親になった経緯" source="single_parent_background" />
            <TextInput fullWidth disabled label="養育費の受け取り状況" source="child_support" />
            <TextInput fullWidth disabled label="離婚回数" source="divorce_count" />
            <TextInput fullWidth disabled label="ひとり親(だった)歴" source="single_parent_period" />
            <TextInput fullWidth disabled label="現在の状況" source="situation" />
            <TextInput fullWidth disabled label="挨拶文" source="greeting_message" />
            <TextInput fullWidth disabled label="面会交流" source="interview" />
            <TextInput fullWidth multiline={true} disabled label="紹介文" source="self_introduction" />
            <TextInput fullWidth multiline={true} disabled label="子供の紹介文" source="child_introduction" />
            <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: 'auto', objectFit: 'contain' } }} source="personal_authentication_image1" />
            <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: 'auto', objectFit: 'contain' } }} source="personal_authentication_image2" />
            <RadioButtonGroupInput label="本人確認ステータス" source="personal_authentication_status" choices={[
                { id: '未認証' , name: '未認証' },
                { id: '認証済み', name: '認証済み' },
            ]} />
        </SimpleForm>
    </Edit>
)};

export const UserCreate = () => (
    <Create title="Create a Post">
        <SimpleForm>
            <TextInput source="title" />
            <TextInput source="teaser" options={{ multiline: true }} />
            <TextInput multiline source="body" />
            <TextInput label="Publication date" source="published_at" />
            <TextInput source="average_note" />
        </SimpleForm>
    </Create>
);
