import api from './../api/request'
// TypeScript users must reference the type: `AuthProvider`
export const authProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }: any) => {
        console.log(password)
        const responst = await fetch(process.env.REACT_APP_API_DOMAIN+"/sanctum/csrf-cookie", {
            headers: {Accept: "application/json"},
            credentials: "include",
        })
        const responseLogin = await api('/api/admin/login', 'POST', {email: username, password}, 'json')
        if (responseLogin.name !== '') {
            localStorage.setItem("name", responseLogin.name);
            localStorage.setItem("type", responseLogin.type);
            return Promise.resolve();
        }
        return Promise.reject();
    },
    // called when the user clicks on the logout button
    logout: () => {
      localStorage.removeItem("name");
      localStorage.removeItem("type");
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }: any) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem("name");
        localStorage.removeItem("type");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return localStorage.getItem("name")
        ? Promise.resolve()
        : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};


