import { fetchUtils, Admin, Resource, ListGuesser } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { UserList, UserEdit } from "./users";
import { NewsList, NewsEdit, NewsCreate } from "./news";
import UserIcon from '@mui/icons-material/People';
import NotificationsIcoon from'@mui/icons-material/Notifications'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { authProvider } from './authProvider'
import dataProvider from './../api/dataProvider'
import { AdminsList, AdminsCreate, AdminsEdit } from "./admins";

const provider = dataProvider(process.env.REACT_APP_API_DOMAIN + "/api/admin", );

const App = () => {
  return (
  <Admin authProvider={authProvider} dataProvider={provider} >
    <Resource  options={{ label: 'ユーザー管理' }} name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
    <Resource  options={{ label: 'お知らせ管理' }} name="news" list={NewsList} edit={NewsEdit} create={NewsCreate} icon={NotificationsIcoon} />
    {
      localStorage.getItem("type") === '1' && (
        <Resource  options={{ label: '管理者管理' }} name="admins" list={AdminsList} edit={AdminsEdit} create={AdminsCreate} icon={SupervisorAccountIcon} />
      )
    }
  </Admin>
)};

export default App;