// in posts.js
import * as React from "react";
import { List, SelectInput, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, useRecordContext, ImageInput, ImageField, RadioButtonGroupInput } from 'react-admin';
import BookIcon from '@mui/icons-material/Book';
export const PostIcon = BookIcon;

export const AdminsList = () => (
    <List title="管理者一覧">
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="name" label="名前" />
            <TextField source="email" label="メールアドレス" />
            <TextField source="type" label="権限" />
            <TextField source="created" label="登録日" />
            <EditButton />
        </Datagrid>
    </List>
);

const AdminsTitle = () => {
    const record = useRecordContext();
    return <span>管理者: {record ? `"${record.title}"` : ''}</span>;
};

export const AdminsEdit = () => (
    <Edit title={<AdminsTitle />}>
        <SimpleForm>
            <TextInput fullWidth disabled label="ID" source="id" />
            <TextInput fullWidth label="名前" source="name" />
            <TextInput fullWidth label="メールアドレス" source="email" />
            <SelectInput fullWidth　source="type" label="権限" choices={[
                { id: '1', name: '管理者' },
                { id: '2', name: '一般' },
                { id: '10', name: '開発者' },
            ]} />
            <DateInput fullWidth disabled label="登録日" source="created" />
        </SimpleForm>
    </Edit>
);

export const AdminsCreate = () => (
    <Create title="管理者新規追加">
        <SimpleForm>
            <TextInput fullWidth label="名前" source="name" />
            <TextInput fullWidth label="メールアドレス" source="email" />
            <TextInput fullWidth label="パスワード" source="password" />
            <SelectInput fullWidth　source="type" label="権限" choices={[
                { id: '1', name: '管理者' },
                { id: '2', name: '一般' },
                { id: '10', name: '開発者' },
            ]} />
        </SimpleForm>
    </Create>
);