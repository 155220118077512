// in posts.js
import * as React from "react";
import { List, SelectInput, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, TextInput, DateInput, useRecordContext, ImageInput, ImageField, RadioButtonGroupInput } from 'react-admin';
import BookIcon from '@mui/icons-material/Book';
export const PostIcon = BookIcon;

export const NewsList = () => (
    <List title="お知らせ一覧">
        <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="type" label="カテゴリ" />
            <TextField source="title" label="タイトル" />
            <TextField source="opened" label="公開日" />
            <EditButton />
        </Datagrid>
    </List>
);

const NewsTitle = () => {
    const record = useRecordContext();
    return <span>お知らせ: {record ? `"${record.title}"` : ''}</span>;
};

export const NewsEdit = () => (
    <Edit title={<NewsTitle />}>
        <SimpleForm>
            <TextInput fullWidth disabled label="ID" source="id" />
            <TextInput fullWidth label="タイトル" source="title" />
            <TextInput multiline fullWidth label="本文" source="body" />
            <TextInput fullWidth label="ボタンラベル" source="button_label" />
            <TextInput fullWidth label="ボタンアクション" source="button_link" />
            <DateInput fullWidth label="公開日" source="opened" />
            <DateInput fullWidth label="終了日" source="closed" />
            <SelectInput fullWidth　source="type_id" label="カテゴリ" choices={[
                { id: '1', name: 'お知らせ' },
                { id: '2', name: '不具合情報' },
                { id: '3', name: '新規機能' },
            ]} />
            <TextInput fullWidth label="対象ユーザーID" source="target_user_id" />
            <SelectInput fullWidth　source="target_os" label="対象OS" choices={[
                { id: '2', name: 'ios' },
                { id: '1', name: 'android' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const NewsCreate = () => (
    <Create title="お知らせ新規追加">
        <SimpleForm>
            <TextInput fullWidth label="タイトル" source="title" />
            <TextInput multiline fullWidth label="本文" source="body" />
            <TextInput fullWidth label="ボタンラベル" source="button_label" />
            <TextInput fullWidth label="ボタンアクション" source="button_link" />
            <DateInput fullWidth label="公開日" source="opened" />
            <DateInput fullWidth label="終了日" source="closed" />
            <SelectInput fullWidth　source="type_id" label="カテゴリ" choices={[
                { id: '1', name: 'お知らせ' },
                { id: '2', name: '不具合情報' },
                { id: '3', name: '新規機能' },
            ]} />
            <TextInput fullWidth label="ボタンアクション" source="target_user_id" />
            <TextInput fullWidth label="対象ユーザーID" source="target_user_id" />
            <SelectInput fullWidth　source="target_os" label="対象OS" choices={[
                { id: '2', name: 'ios' },
                { id: '1', name: 'android' },
            ]} />
        </SimpleForm>
    </Create>
);